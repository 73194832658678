import { Inject, Injectable } from '@angular/core';

import { Observable, filter } from 'rxjs';

import { IdentityService } from 'sc-common';
import { ENV_TOKEN, IEnvironment } from 'sc-common/core/models/environment';
import { BACKGROUND_PROCESS_CONFIG, BackgroundProcessConfig } from 'sc-common/core/services/background-process/background-process-config';
import { GlobalErrorHandler } from 'sc-common/core/services/error-handler/error-handler.service';
import { ApiBackgroundProcessMessage, BackgroundProcessEnum, ServicesEnum } from 'sc-common/core/services/open-api/open-api-clients';
import { SignalRBaseClientService } from 'sc-common/core/services/signalr/signalr-base-client.service';

@Injectable({ providedIn: 'root' })
export class BackgroundProcessService extends SignalRBaseClientService {

    public progress$: Observable<ApiBackgroundProcessMessage>;

    constructor(
        identityService: IdentityService,
        protected readonly _errorHandler: GlobalErrorHandler,
        @Inject(ENV_TOKEN) env: IEnvironment,
        @Inject(BACKGROUND_PROCESS_CONFIG) private readonly config: BackgroundProcessConfig) {

        super(identityService, _errorHandler, `${ env.apiUrl }/api/signalrhub/backgroundprocesses`);

        this.progress$ = this.getCallbackSource$('backgroundProcessProgress').pipe(
            filter((message: ApiBackgroundProcessMessage) => this._isAllowedProcess(message.services)))
    }

    public onComplete(...processes: BackgroundProcessEnum[]): Observable<ApiBackgroundProcessMessage> {
        return this.progress$.pipe(filter(m => processes.includes(m.process) && m.completed));
    }

    private _isAllowedProcess(process: ServicesEnum): boolean {
        return !this.config.allowedServicess || this.config.allowedServicess.length === 0 ||
            this.config.allowedServicess.includes(process);
    }
}
