import { buildEnumMapProvider, FileUploadErrorEnum } from 'sc-common';

export const fileUploadErrorEnumProvider = buildEnumMapProvider(FileUploadErrorEnum, getEnumText);

function getEnumText(value: FileUploadErrorEnum): string {

    switch (value) {

        case FileUploadErrorEnum.VersionAlreadyImported:
            return $localize`Version already imported`;

        case FileUploadErrorEnum.InvalidFile:
            return $localize`Invalid file`;

        case FileUploadErrorEnum.WrongExtension:
            return $localize`Invalid file extension`;

        case FileUploadErrorEnum.ExceededSize:
            return $localize`File size is too big`;

        case FileUploadErrorEnum.MissingSize:
            return $localize`File size is too small`;

        case FileUploadErrorEnum.ZipDoesNotContainTex:
            return $localize`Zip file does not contain any .tex file`;

        case FileUploadErrorEnum.WrongHeight:
            return $localize`Wrong file height`;

        case FileUploadErrorEnum.WrongWidth:
            return $localize`Wrong file width`;

        case FileUploadErrorEnum.FileNameNumericOnly:
            return $localize`File names should be numeric only`;

        case FileUploadErrorEnum.RepeatedPageFrom:
            return $localize`There are more then one paper with the same page from`;

        case FileUploadErrorEnum.Encrypted:
            return $localize`File should not be encrypted`;

        case FileUploadErrorEnum.FileHasNoPair:
            return $localize`Some files are missing their pair`;

        case FileUploadErrorEnum.CannotReplace:
            return $localize`Can't find existing files to replace`;

        case FileUploadErrorEnum.BulkFileContainsErrors:
            return $localize`Bulk file contains errors`;

        case FileUploadErrorEnum.MaxFileCount:
            return $localize`Maximum number of files is already added`;

        case FileUploadErrorEnum.EmailIsRequired:
            return $localize`Email is required`;
    }
}

