import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { HttpErrorInterceptor } from 'sc-common/core/interceptors/http-error/http-error.interceptor';
import { SetBaseUrlInterceptor } from 'sc-common/core/interceptors/set-base-url/set-base-url.interceptor';
import { BACKGROUND_PROCESS_CONFIG } from 'sc-common/core/services/background-process/background-process-config';
import { apiErrorCodeProvider } from 'sc-common/core/services/enum/api-error-code-map';
import { backgroundProcessEnumProvider } from 'sc-common/core/services/enum/background-process-enum-map';
import { backgroundProcessStatusEnumMapProvider } from 'sc-common/core/services/enum/background-process-status-enum-map';
import { backgroundProcessStepStatusMapProvider } from 'sc-common/core/services/enum/background-process-step-status-map';
import { bulkMemberErrorEnumMapProvider } from 'sc-common/core/services/enum/bulk-member-error-enum-map';
import { fileUploadErrorEnumProvider } from 'sc-common/core/services/enum/file-upload-error-enum-map';
import { noteTypeEnumMap } from 'sc-common/core/services/enum/note-type-enum-map';
import { paperStatusEnumMapProvider } from 'sc-common/core/services/enum/paper-status-enum-map';
import { servicesEnumProvider } from 'sc-common/core/services/enum/services-enum-map';
import { timerTaskTypeProvider } from 'sc-common/core/services/enum/timer-task-type-map';
import { ODataService } from 'sc-common/core/services/odata/odata.service';
import { TableLocalizingService } from 'sc-common/core/services/table-localizing.service';

@NgModule({
    imports: [],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SetBaseUrlInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: BACKGROUND_PROCESS_CONFIG,
            useValue: {}
        },
        ODataService,
        TableLocalizingService,
        fileUploadErrorEnumProvider,
        apiErrorCodeProvider,
        paperStatusEnumMapProvider,
        backgroundProcessEnumProvider,
        backgroundProcessStatusEnumMapProvider,
        backgroundProcessStepStatusMapProvider,
        bulkMemberErrorEnumMapProvider,
        noteTypeEnumMap,
        servicesEnumProvider,
        timerTaskTypeProvider
    ]
})
export class CommonCoreModule {

    constructor(@SkipSelf() @Optional() selfRef?: CommonCoreModule) {
        if (selfRef) {
            throw new Error('There must be only one instance');
        }
    }
}
