import { TimerTaskType, buildEnumMapProvider } from 'sc-common';

export const timerTaskTypeProvider = buildEnumMapProvider(TimerTaskType, getEnumText);

function getEnumText(value: TimerTaskType): string {
    switch (value) {
        case TimerTaskType.CountryIpRange:
            return $localize`Country Ip Range`;

        case TimerTaskType.Grid:
            return $localize`Grid`;

        case TimerTaskType.SavedSearch:
            return $localize`Saved Search`;

        case TimerTaskType.CounterReportAggregator:
            return $localize`COUNTER Report Aggregator`;

        case TimerTaskType.SendGridBounces:
            return $localize`SendGrid Bounces`;

        case TimerTaskType.UpdateMarketingCampaignFromSendGrid:
            return $localize`Update Marketing Campaign from SendGrid`;

        case TimerTaskType.CleanUpAllSendGridContacts:
            return $localize`Clean up all SendGrid Contacts`;
    }
}
